import React from 'react';
import { useAsync, UseAsyncReturn } from 'react-async-hook';

import { getMatterport } from 'appAPI';
import { delayPromise } from '@shoootin/utils';
import { usePageParams } from 'layout/appContext';
import { isDev } from 'appEnv';
import { AppSEOTitle } from '../../appSEO';
import PageError, { PageErrorContent } from '../pageError';
import { MainLayout } from '../../layout/mainLayout';
import {
  ShSingleMatterportLoading,
  ShSingleMatterport,
} from '@shoootin/components-web';

import { ShMatterportClientDTO } from '@shoootin/api';

const isObjectIdValid = (objectId: string) => {
  return objectId.match(/^[0-9a-fA-F]{24}$/);
};

export const MatterportLoading = () => (
  <div>
    <AppSEOTitle title="Matterport" />
    <ShSingleMatterportLoading />
  </div>
);

const MatterportLayout = ({
  matterport,
}: {
  matterport: ShMatterportClientDTO;
}) => {
  return (
    <div>
      <AppSEOTitle title={`Matterport ${matterport ? matterport.title : ''}`} />
      <ShSingleMatterport matterport={matterport} />
    </div>
  );
};

const ScanPage = () => {
  const { matterportId } = usePageParams();

  if (!matterportId) {
    return <PageError />;
  }

  const asyncMatterport: UseAsyncReturn<ShMatterportClientDTO> = useAsync(async () => {
    if (isObjectIdValid(matterportId)) {
      if (isDev) {
        await delayPromise(2000);
      }
      return getMatterport({ id: matterportId });
    } else {
      throw new Error('Incorrect url');
    }
  }, [matterportId]);

  return (
    <div>
      {asyncMatterport.loading && <MatterportLoading />}
      {asyncMatterport.error && (
        <>
          <MainLayout className="light" showContact={false} pageName="error">
            <PageErrorContent message={asyncMatterport.error.message} />
          </MainLayout>
        </>
      )}
      {asyncMatterport.result && (
        <MatterportLayout matterport={asyncMatterport.result} />
      )}
    </div>
  );
};

export default ScanPage;
